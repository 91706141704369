
import {
  DxDataGrid, DxColumn,   DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
 
    DxPaging,
    DxToast,
  },
  data() {
    return {
      dataSourceCompany:[
                     {id: 3,
    title: 'Proyecto Cliente 1 XXXX - Aguas Residuales - Gestionar realización de los pagos correspondientes al consumo de agua de la red',
    start: new Date('2019-02-21T05:00:00.000Z'),
    end: new Date('2019-02-21T09:00:00.000Z'),
    progress: 20,
  }, {
    id: 4,
 
    title: 'Proyecto Cliente 1 XXXX - Aguas Residuales - Gestionar realización de los pagos correspondientes al consumo de agua de pozo',
    start: new Date('2019-02-21T10:00:00.000Z'),
    end: new Date('2019-02-22T09:00:00.000Z'),
    progress: 10,
  }, {
    id: 5,
 
    title: 'Proyecto Cliente 1 XXXX - Aguas Residuales - Generar programa de mantenimiento de tuberías, tanques de almacenamiento, equipos mecánicos y electrónicos',
    start: new Date('2019-02-22T10:00:00.000Z'),
    end: new Date('2019-02-25T09:00:00.000Z'),
    progress: 60,
  }, {
    id: 6,
  
    title: 'Proyecto Cliente 2 XXXX - Aguas Residuales - Gestionar realización de los pagos correspondientes al consumo de agua de la red',
    start: new Date('2019-02-25T10:00:00.000Z'),
    end: new Date('2019-02-26T09:00:00.000Z'),
    progress: 0,
  }, {
    id: 7,
 
    title: 'Proyecto Cliente 5 XXXX - Aguas Residuales - Gestionar realización de los pagos correspondientes al consumo de agua de la red',
    start: new Date('2019-02-26T09:00:00.000Z'),
    end: new Date('2019-02-26T09:00:00.000Z'),
    progress: 0,
  },
                  ],
      empresa: {},
      events: [],
      companyService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.companyService = new CompanyService();
  },
  mounted() {    
    //this.companyService.getEmpresas().then(data => this.dataSourceCompany=data);

    //console.log(this.companyService.getEmpresas().then(data => this.dataSourceCompany=data));
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editCompany() {

      /**
            this.companyService.updateEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
       */
        },
    deleteCompany() {
      /**
            this.companyService.deleteEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
       */
        },
    createCompany() {
      /**
            this.companyService.createEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
      */
        },
  },
};
